import './chunk-455cdeae.js';
import './helpers.js';
import './chunk-e92e3389.js';
import './chunk-17b33cd2.js';
import './chunk-7e17a637.js';
import { r as registerComponent, u as use } from './chunk-cca88db8.js';
import './chunk-83c8b459.js';
import './chunk-60a03517.js';
import './chunk-42f463e6.js';
import './chunk-ade5b253.js';
import './chunk-d46e7ff0.js';
import './chunk-4e788733.js';
import { D as Datepicker } from './chunk-6c64686f.js';
export { D as BDatepicker } from './chunk-6c64686f.js';

var Plugin = {
  install: function install(Vue) {
    registerComponent(Vue, Datepicker);
  }
};
use(Plugin);

export default Plugin;
